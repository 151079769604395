.App {
  font-family: "Public Sans", sans-serif;
}

.container {
  padding: 0 50px;
}

@media (max-width: 430px) {
  .container {
    padding: 0 25px;
  }

  .swiper-pagination {
    margin-left: 25px !important;
  }

  .top {
    padding: 50px 25px 20px 25px !important;
  }
}

@media (max-width: 540px) {
  .footer .info {
    flex-direction: column;
  }
}