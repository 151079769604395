.faq-section {
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: left;
    background-color: #FFF4E2;
}

.faq-section h2 {
    text-align: center;
    margin-bottom: 40px;
}

.faq-item {
    margin-bottom: 20px;
    background-color: #FFF;
    padding: 30px;
    border-radius: 15px;
}

.faq-item:hover {
    color: #FFF;
    background-color: #3F539E;
}

.faq-item:hover .faq-answer {
    color: #000;
}

.faq-question {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.faq-answer {
    margin-top: 10px;
    background-color: #eee;
    padding: 20px;
    border-radius: 15px;
}