.swiper {
    height: 100%;
}

.swiper-slide {
    height: auto !important;
}

.swiper-content {
    height: 100% !important
}

.slide-container {
    height: 100%;
    background-image: url(../img/sliderBackground.png);
}

.slide1 {
    background-color: #fff4e2;
}

.slide2 {
    background-color: #eaecff;
}

.slide3 {
    background-color: #dff4ff;
}

.content-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
}

.image-wrapper {
    display: flex;
    justify-content: center;
}

.content-wrapper .image-wrapper img {
    width: 100%;
    margin-bottom: -10px;
}

.text-wrapper {
    padding: 50px 0;
    color: #494949;
    flex: 1 365px;
}

.text-wrapper .lead-in h2 {
    display: flex;
    flex-direction: column;
}

.cta {
    background-color: rgba(0, 0, 0, 0.9);
    background-image: url('../img/pattern.png');
    color: #fff;
    border: none;
    border-radius: 10px;
    padding: 15px 25px;
    cursor: pointer;
}

.swiper-pagination {
    text-align: left;
    margin-left: 50px;
    margin-bottom: 20px;
}

.swiper-pagination-bullet {
    width: 15px;
    height: 15px;
}

.swiper-pagination-bullet-active {
    background: #191945;
}

@media (max-width:376px) {
    .text-wrapper .lead-in p {
        font-size: 1rem;
    }
}