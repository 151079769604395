.navbar {
    display: flex;
    position: sticky;
    top: 0;
    z-index: 100;
    justify-content: space-between;
    align-items: center;
    padding: 30px 50px;
    background-color: #fff;
    color: #333;
}

.logo {
    font-size: 1.5em;
}

.navbar-toggler {
    display: none;
    font-size: 1.5em;
    background: none;
    border: none;
    color: #000;
    cursor: pointer;
}

.nav-links {
    display: flex;
    align-items: center;
    gap: 24px;
}

.nav-links ul {
    display: flex;
    gap: 0;
    list-style: none;
    margin: 0;
    padding: 0;
}

.nav-links li {
    margin: 0;
    padding: 15px 20px;
    cursor: pointer;
}

.nav-links li:hover {
    background-color: #555;
    color: #fff;
}

.get-started {
    background-color: rgba(0, 0, 0, 0.9);
    background-image: url('../img/pattern.png');
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    border: none;
    border-radius: 10px;
    padding: 15px 25px;
    cursor: pointer;
}

.nav-links .nav-btns {
    background: none;
    border: none;
    color: inherit;
    font: inherit;
    cursor: pointer;
    padding: 0;
}

@media (max-width: 768px) {
    .navbar {
        padding: 30px 25px;
    }

    .navbar-toggler {
        display: block;
    }

    .nav-links {
        display: none;
        flex-direction: column;
        align-items: start;
        width: 100%;
        background-color: rgba(253, 252, 252);
        color: #050505;
        position: absolute;
        top: 90px;
        left: 0;
        z-index: 10;
    }

    .nav-links.open {
        display: flex;
    }

    .nav-links ul {
        width: 100%;
        flex-direction: column;
        margin-top: 10px;
        margin-left: 0px;
        text-align: left;
        gap: 0;
    }

    .nav-links li {
        margin: 0;
        padding: 15px 20px;
        cursor: pointer;
    }

    .nav-links li:hover {
        background-color: #555;
        color: #fff;
    }

    .get-started {
        margin: 10px 20px;
    }
}