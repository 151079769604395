.download-section {
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
    background-image: url(../img/dwnbckg.png);
    color: #fff;
}

/* .download-section h2 {
    font-size: 48px;
} */

.download-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.apple-store,
.play-store {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    cursor: pointer;
    min-width: 170px;
    min-height: 50px;
    background-repeat: no-repeat;
    background-size: cover;
}

.apple-store {
    background-image: url(../img/app_store.png);
}

.play-store {
    background-image: url(../img/google_play.png);
}