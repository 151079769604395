.contact-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #fff;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    order: 2;
    flex: 1 365px;
}

.contact-form h2 {
    font-weight: 700;
}

.contact-form h2 span {
    color: #3f539e;
}

.contact-form form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: left;
}

.contact-form form div {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.image-container {
    display: flex;
    align-items: start;
    flex: 1 365px;
    order: 1;
}

.image-container img {
    width: 100%;
    height: auto;
}

.form input {
    padding: 20px;
    border: 1px solid #777;
    border-radius: 5px;
    width: 100%;
}

.form input[name='honeypot'] {
    display: none;
}

.form input[type='submit'] {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    background-color: #000;
    background-image: url(../img/pattern.png);
}

@media (max-width: 853px) {
    .image-container {
        order: 2;
    }

    .contact-form {
        order: 1;
    }
}