.video-section {
    background-color: #FFF;
    padding: 50px;
}

.about-video {
    width: 100%;
    text-align: center;
}

iframe {
    border-radius: 20px;
    max-width: 1280px;
    max-height: 488px;
    height: 50vh;
}