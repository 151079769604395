.footer {
    background-color: #fff;
    color: #333;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.footer .top {
    padding: 100px 100px 20px 100px;
}

.footer .base {
    background-image: url(../img/pattern.png);
    width: 100%;
    height: 80px;
}

.footer .info {
    border-bottom: 2px dashed #333;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding-bottom: 20px;
}

.footer-card {
    display: flex;
    flex-direction: column;
    min-height: 150px;
    margin-bottom: 10px;
}

.footer-card .heading {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    height: 60px;
    font-size: 20px;
}

.footer-card .heading h4 {
    color: #000;
    margin: 0;
}

.footer-description {
    flex: 2;
}

.footer-sitemap {
    flex: 1;
}

.footer-sitemap ul {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding-left: 25px;
    gap: 15px;
}

.footer-newsletter {
    flex: 3;
}

.footer-newsletter .body {
    border: 1px solid #000;
    border-right: none;
    border-radius: 5px;
}

.footer-newsletter .body input[type="email"] {
    padding: 10px;
    width: calc(70%);
    border: none;
    outline: none;
    border-radius: 5px;
    background-color: none;
}

.footer-newsletter .body input[type="email"]:focus {
    border: none;
    outline: none;
}

.footer-newsletter .body input[type="submit"] {
    padding: 10px;
    border: none;
    border-radius: 5px;
    width: calc(30%);
    height: 100%;
    color: #fff;
    background-color: #3F539E;
    cursor: pointer;
}

.footer-social {
    flex: 1;
}

.footer-social ul {
    list-style: none;
    padding: 0;
    display: flex;
    gap: 10px;
}

.top .copyright {
    text-align: center;
    padding-top: 30px;
}