.about-section {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    background-color: #EAECFF;
}

.about-pap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.about-pap h2 {
    text-align: center;
    margin-bottom: 50px;
}

.about-pap .content {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
    gap: 50px;
}

.mockup-image {
    display: flex;
    justify-content: center;
    align-items: end;
}

.mockup-image img {
    max-width: calc(50% + 100px);
}

.feature-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex: 2 calc(50%);
}

.feature-item {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 20px;
    padding: 20px;
}

.icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 80px;
    width: 80px;
    max-width: 80px;
    box-shadow: -2px 5px 5px #4e4e4e;
    border-radius: 50%;
    background-color: #fff;
}

.icon-container img {
    width: 50%;
}

.feature-text {
    flex: 2;
}